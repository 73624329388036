import BCHJS from '@bcpros/xpi-js';
import { walletAdapter, WalletState } from '@store/wallet';
import BigNumber from 'bignumber.js';
import {
  ChronikClientNode,
  ScriptUtxo_InNode,
  ScriptUtxos_InNode,
  Tx_InNode,
  TxHistoryPage_InNode,
  Utxo_InNode
} from 'chronik-client';
import { Hash160AndAddress } from '@bcpros/lixi-models/lib/wallet/wallet.model';
import { decryptOpReturnMsg, getSelectedWallet, parseOpReturn } from './cashMethodsNode';
import { parseBurnOutput, ParseBurnResult } from './opReturnBurn';
import { coinInfo } from '@bcpros/lixi-models/constants/coins/coin-info';
import { TX_HISTORY_COUNT, COIN } from '@bcpros/lixi-models/constants/coins/coin';

export interface ParsedChronikTx_InNode {
  incoming: boolean;
  xecAmount: string;
  originatingHash160: string;
  opReturnMessage: string;
  isLotusMessage: boolean;
  isEncryptedMessage: boolean;
  decryptionSuccess: boolean;
  replyAddress: string;
  replyAddressHash: string;
  destinationAddress: string;
  destinationAddressHash: string;
  // Burn
  isBurn: boolean;
  burnInfo?: ParseBurnResult;
  xecBurnAmount: string;
}

const getWalletPathsFromWalletState = (wallet: WalletState) => {
  const entities = wallet.entities;
  return Object.entries(entities).map(([key, value]) => {
    return value;
  });
};

const getSelectedWalletPathFromWalletState = (wallet: WalletState) => {
  let selectedPath;
  Object.entries(wallet.entities).map(([key, value]) => {
    if (wallet.selectedWalletPath === key) {
      selectedPath = value;
    }
  });

  return selectedPath;
};

/* 
Note: chronik.script('p2pkh', hash160).utxos(); is not readily mockable in jest
Hence it is necessary to keep this out of any functions that require unit testing
*/
export const getUtxosSingleHashChronik = async (
  chronik: ChronikClientNode,
  hash160: string
): Promise<Array<ScriptUtxo_InNode>> => {
  // Get utxos at a single address, which chronik takes in as a hash160
  try {
    const { utxos } = await chronik.script('p2pkh', hash160).utxos();

    if (utxos.length === 0) {
      // Chronik returns an empty array if there are no utxos at this hash160
      return [];
    }
    /* Chronik returns an array of with a single object if there are utxos at this hash 160
    [
        {
            outputScript: <hash160>,
            utxos:[{utxo}, {utxo}, ..., {utxo}]
        }
    ]
    */

    // Return only the array of utxos at this address
    return utxos;
  } catch (err) {
    console.log(`Error in chronik.utxos(${hash160})`, err);
  }
};

export const returnGetUtxosChronikPromise = (
  chronik: ChronikClientNode,
  hash160AndAddressObj: Hash160AndAddress
): Promise<Array<Utxo_InNode & { address: string }>> => {
  /*
      Chronik thinks in hash160s, but people and wallets think in addresses
      Add the address to each utxo
  */
  return new Promise((resolve, reject) => {
    getUtxosSingleHashChronik(chronik, hash160AndAddressObj.hash160).then(
      result => {
        if (!result) reject();
        for (let i = 0; i < result.length; i += 1) {
          const thisUtxo = result[i];
          (thisUtxo as any).address = hash160AndAddressObj.address;
        }
        resolve(result as Array<Utxo_InNode & { address: string }>);
      },
      err => {
        reject(err);
      }
    );
  });
};

export const getUtxosChronik = async (
  chronik: ChronikClientNode,
  hash160sMappedToAddresses: Array<Hash160AndAddress>
): Promise<Array<Utxo_InNode & { address: string }>> => {
  /* 
      Chronik only accepts utxo requests for one address at a time
      Construct an array of promises for each address
      Note: Chronik requires the hash160 of an address for this request
  */
  if (!chronik || !hash160sMappedToAddresses) return [];

  const chronikUtxoPromises: Array<Promise<Array<Utxo_InNode & { address: string }>>> = [];
  for (let i = 0; i < hash160sMappedToAddresses.length; i += 1) {
    const thisPromise = returnGetUtxosChronikPromise(chronik, hash160sMappedToAddresses[i]);
    chronikUtxoPromises.push(thisPromise);
  }
  const allUtxos = await Promise.all(chronikUtxoPromises);
  // Since each individual utxo has address information, no need to keep them in distinct arrays
  // Combine into one array of all utxos
  const flatUtxos = allUtxos.flat();
  return flatUtxos;
};

export const organizeUtxosByType = (
  chronikUtxos: Array<Utxo_InNode & { address: string }>
): { nonSlpUtxos: Array<Utxo_InNode & { address: string }> } => {
  /* 
  Convert chronik utxos (returned by getUtxosChronik function, above) to match 
  shape of existing slpBalancesAndUtxos object
  */

  const nonSlpUtxos = [];
  for (let i = 0; i < chronikUtxos.length; i += 1) {
    // Construct nonSlpUtxos and slpUtxos arrays
    const thisUtxo = chronikUtxos[i];
    if (typeof thisUtxo.token !== 'undefined') {
    } else {
      nonSlpUtxos.push(thisUtxo);
    }
  }

  return { nonSlpUtxos };
};

export const flattenChronikTxHistory = (txHistoryOfAllAddresses: TxHistoryPage_InNode[]): Tx_InNode[] => {
  // Create an array of all txs

  let flatTxHistoryArray: Tx_InNode[] = [];
  for (let i = 0; i < txHistoryOfAllAddresses.length; i += 1) {
    const txHistoryResponseOfThisAddress = txHistoryOfAllAddresses[i];
    const txHistoryOfThisAddress = txHistoryResponseOfThisAddress.txs;
    flatTxHistoryArray = flatTxHistoryArray.concat(txHistoryOfThisAddress);
  }
  return flatTxHistoryArray;
};

// @todo
export const sortAndTrimChronikTxHistory = (flatTxHistoryArray: Tx_InNode[], txHistoryCount: number): Tx_InNode[] => {
  // Isolate unconfirmed txs
  // In chronik, unconfirmed txs have an `undefined` block key
  const unconfirmedTxs = [];
  const confirmedTxs = [];
  for (let i = 0; i < flatTxHistoryArray.length; i += 1) {
    const thisTx = flatTxHistoryArray[i];
    if (typeof thisTx.block === 'undefined') {
      unconfirmedTxs.push(thisTx);
    } else {
      confirmedTxs.push(thisTx);
    }
  }
  // Sort confirmed txs by blockheight, and then timeFirstSeen
  const sortedConfirmedTxHistoryArray = confirmedTxs.sort(
    (a, b) =>
      // We want more recent blocks i.e. higher blockheights to have earlier array indices
      b.block.height - a.block.height ||
      // For blocks with the same height, we want more recent timeFirstSeen i.e. higher timeFirstSeen to have earlier array indices
      b.timeFirstSeen - a.timeFirstSeen
  );
  // Sort unconfirmed txs by timeFirstSeen
  const sortedUnconfirmedTxHistoryArray = unconfirmedTxs.sort((a, b) => b.timeFirstSeen - a.timeFirstSeen);
  // The unconfirmed txs are more recent, so they should be inserted into an array before the confirmed txs
  const sortedChronikTxHistoryArray = sortedUnconfirmedTxHistoryArray.concat(sortedConfirmedTxHistoryArray);

  const trimmedAndSortedChronikTxHistoryArray = sortedChronikTxHistoryArray.splice(0, txHistoryCount);

  return trimmedAndSortedChronikTxHistoryArray;
};

export const returnGetTxHistoryChronikPromise = (
  chronik: ChronikClientNode,
  hash160AndAddressObj: Hash160AndAddress,
  pageNumber: number
): Promise<TxHistoryPage_InNode> => {
  /*
      Chronik thinks in hash160s, but people and wallets think in addresses
      Add the address to each utxo
  */
  return new Promise((resolve, reject) => {
    chronik
      .script('p2pkh', hash160AndAddressObj.hash160)
      .history(/*page=*/ pageNumber ? pageNumber : 0, /*page_size=*/ TX_HISTORY_COUNT)
      .then(
        result => {
          resolve(result);
        },
        err => {
          reject(err);
        }
      );
  });
};

export const getRecipientPublicKey = async (
  XPI: BCHJS,
  chronik: ChronikClientNode,
  recipientAddress: string,
  optionalMockPubKeyResponse: string | false = false
): Promise<string | false> => {
  // Necessary because jest can't mock
  // chronikTxHistoryAtAddress = await chronik.script('p2pkh', recipientAddressHash160).history(/*page=*/ 0, /*page_size=*/ 10);
  if (optionalMockPubKeyResponse) {
    return optionalMockPubKeyResponse;
  }

  // get hash160 of address
  let recipientAddressHash160: string;
  try {
    recipientAddressHash160 = XPI.Address.toHash160(recipientAddress);
  } catch (err) {
    console.log(`Error determining XPI.Address.toHash160(${recipientAddress} in getRecipientPublicKey())`, err);
    // throw new Error(`Error determining XPI.Address.toHash160(${recipientAddress} in getRecipientPublicKey())`);
  }

  let chronikTxHistoryAtAddress: TxHistoryPage_InNode;
  try {
    // Get 20 txs. If no outgoing txs in those 20 txs, just don't send the tx
    chronikTxHistoryAtAddress = await chronik
      .script('p2pkh', recipientAddressHash160)
      .history(/*page=*/ 0, /*page_size=*/ 20);
  } catch (err) {
    console.log(`Error getting await chronik.script('p2pkh', ${recipientAddressHash160}).history();`, err);
    throw new Error('Error fetching tx history to parse for public key');
  }

  let recipientPubKeyChronik;

  // Iterate over tx history to find an outgoing tx
  for (let i = 0; i < chronikTxHistoryAtAddress.txs.length; i += 1) {
    const { inputs } = chronikTxHistoryAtAddress.txs[i];
    for (let j = 0; j < inputs.length; j += 1) {
      const thisInput = inputs[j];
      const thisInputSendingHash160 = thisInput.outputScript;
      if (thisInputSendingHash160.includes(recipientAddressHash160)) {
        // Then this is an outgoing tx, you can get the public key from this tx
        // Get the public key
        try {
          recipientPubKeyChronik = chronikTxHistoryAtAddress.txs[i].inputs[j].inputScript.slice(-66);
        } catch (err) {
          throw new Error('Cannot send an encrypted message to a wallet with no outgoing transactions');
        }
        return recipientPubKeyChronik;
      }
    }
  }
  // You get here if you find no outgoing txs in the chronik tx history
  throw new Error('Cannot send an encrypted message to a wallet with no outgoing transactions in the last 20 txs');
};

export const parseChronikTx_InNode = async (
  XPI: BCHJS,
  chronik: ChronikClientNode,
  tx: Tx_InNode,
  wallet: WalletState,
  coin = COIN.XPI
): Promise<ParsedChronikTx_InNode> => {
  const selectedWallet = getSelectedWallet(wallet);
  const { inputs, outputs } = tx;
  // Assign defaults
  let incoming = true;
  let xecAmount = new BigNumber(0);
  let originatingHash160 = '';

  // Burn
  let isBurn = false;
  let xecBurnAmount = new BigNumber(0);
  let parseBurnResult;

  // Initialize required variables
  let messageHex = '';
  let opReturnMessage: string;
  let isLotusMessage = false;
  let isEncryptedMessage = false;
  let decryptionSuccess = false;
  let replyAddress = ''; // or senderAddress
  let replyAddressHash = ''; // or senderAddressHash
  let destinationAddress = '';
  let destinationAddressHash = '';

  // Iterate over inputs to see if this is an incoming tx (incoming === true)
  for (let i = 0; i < inputs.length; i += 1) {
    const thisInput = inputs[i];
    const thisInputSendingHash160 = thisInput.outputScript;

    try {
      const legacyReplyAddress = XPI.Address.fromOutputScript(Buffer.from(thisInput.outputScript, 'hex'));
      replyAddress = XPI.Address.toXAddress(legacyReplyAddress);
      replyAddressHash = XPI.Address.toHash160(legacyReplyAddress);
    } catch (err) {
      console.log(`err from ${originatingHash160}`, err);
      // If the transaction is nonstandard, don't worry about a reply address for now
      originatingHash160 = 'N/A';
    }

    // Incoming transaction means that all inputs are not from current addresses
    if (thisInputSendingHash160.includes(selectedWallet.hash160)) {
      // Then this is an outgoing tx
      incoming = false;
    }
  }

  // Iterate over outputs to get the amount sent
  for (let i = 0; i < outputs.length; i += 1) {
    const thisOutput = outputs[i];
    const thisOutputReceivedAtHash160 = thisOutput.outputScript;
    // Check for OP_RETURN msg
    if (thisOutput.value === 0 && typeof thisOutput.token === 'undefined') {
      const hex = thisOutputReceivedAtHash160;
      const parsedOpReturnArray = parseOpReturn(hex);

      if (!parsedOpReturnArray) {
        console.log('parseChronikTx_InNode() error: parsed array is empty');
        break;
      }

      const txType = parsedOpReturnArray[0];

      messageHex = txType; // index 0 is the message content in this instance

      // if there are more than one part to the external message
      const arrayLength = parsedOpReturnArray.length;
      for (let i = 1; i < arrayLength; i++) {
        messageHex = messageHex + parsedOpReturnArray[i];
      }
    }

    // Find amounts at your wallet's addresses
    if (thisOutputReceivedAtHash160.includes(selectedWallet.hash160)) {
      // If incoming tx, this is amount received by the user's wallet
      // if outgoing tx (incoming === false), then this is a change amount
      const thisOutputAmount = new BigNumber(thisOutput.value);
      xecAmount = incoming ? xecAmount.plus(thisOutputAmount) : xecAmount.minus(thisOutputAmount);
    }
    // Output amounts not at your wallet are sent amounts if !incoming
    if (!incoming) {
      const thisOutputAmount = new BigNumber(thisOutput.value);
      xecAmount = xecAmount.plus(thisOutputAmount);
      try {
        if (!destinationAddress) {
          // Assumpt the destination address is the first output
          const legacyDestinationAddress = XPI.Address.fromOutputScript(Buffer.from(thisOutput.outputScript, 'hex'));
          destinationAddress = XPI.Address.toXAddress(legacyDestinationAddress);
          destinationAddressHash = XPI.Address.toHash160(legacyDestinationAddress);
        }
      } catch (err) {}
    }
  }

  // Convert from sats to coin
  const cashDecimals = coinInfo[COIN.XEC].cashDecimals;
  xecAmount = xecAmount.shiftedBy(-1 * cashDecimals);
  if (isBurn) {
    xecBurnAmount = xecBurnAmount.shiftedBy(-1 * cashDecimals);
  }
  // Convert from BigNumber to string
  const xecAmountString = xecAmount.toString();
  const xecBurnAmountString = xecBurnAmount.toString();

  // Convert messageHex to string
  const theOtherAddress = incoming ? replyAddress : destinationAddress;
  let otherPublicKey;
  try {
    otherPublicKey = await getRecipientPublicKey(XPI, chronik, theOtherAddress);
  } catch (err) {}

  if (
    isLotusMessage &&
    isEncryptedMessage &&
    theOtherAddress &&
    otherPublicKey &&
    wallet &&
    wallet.walletStatusNode &&
    wallet.walletStatusNode.slpBalancesAndUtxos &&
    wallet.walletStatusNode.slpBalancesAndUtxos.nonSlpUtxos[0]
  ) {
    const fundingWif = selectedWallet.fundingWif;
    const decryption = await decryptOpReturnMsg(messageHex, fundingWif, otherPublicKey);

    if (decryption.success) {
      opReturnMessage = Buffer.from(decryption.decryptedMsg).toString('utf8');
      decryptionSuccess = true;
    } else {
      opReturnMessage = 'Error in decrypting message!';
    }
  }
  const parsedTx: ParsedChronikTx_InNode = {
    incoming,
    xecAmount: xecAmountString,
    originatingHash160,
    opReturnMessage,
    isLotusMessage,
    isEncryptedMessage,
    decryptionSuccess,
    replyAddress,
    destinationAddress,
    isBurn,
    xecBurnAmount: xecBurnAmountString,
    replyAddressHash,
    destinationAddressHash
  };
  return parsedTx;
};

export const getTxHistoryChronik = async (
  chronik: ChronikClientNode,
  XPI: BCHJS,
  wallet: WalletState,
  pageNumber = 0,
  coin = COIN.XPI
): Promise<{ chronikTxHistory: Array<Tx_InNode & { parsed: ParsedChronikTx_InNode }> }> => {
  // Create array txHistory with selectedPath
  const walletPathSelected = getSelectedWalletPathFromWalletState(wallet);

  const hash160AndADresssObj: Hash160AndAddress = {
    address: walletPathSelected.xAddress,
    hash160: walletPathSelected.hash160
  };

  const txHistoryPromise: Promise<TxHistoryPage_InNode> = returnGetTxHistoryChronikPromise(
    chronik,
    hash160AndADresssObj,
    pageNumber
  );
  let txHistoryOfAllAddresses: TxHistoryPage_InNode;
  try {
    txHistoryOfAllAddresses = await Promise.resolve(txHistoryPromise);
  } catch (err) {
    console.log(`Error in Promise.all(txHistoryPromises)`, err);
  }
  const sortedTxHistoryArray = sortAndTrimChronikTxHistory(txHistoryOfAllAddresses.txs, TX_HISTORY_COUNT);

  // Parse txs
  const chronikTxHistory: Array<Tx_InNode & { parsed: ParsedChronikTx_InNode }> = [];
  for (let i = 0; i < sortedTxHistoryArray.length; i += 1) {
    const sortedTx: any = sortedTxHistoryArray[i];
    // Add token genesis info so parsing function can calculate amount by decimals
    sortedTx.parsed = await parseChronikTx_InNode(XPI, chronik, sortedTx, wallet, coin);
    chronikTxHistory.push(sortedTx as Tx_InNode & { parsed: ParsedChronikTx_InNode });
  }

  return {
    chronikTxHistory
  };
};
