import BCHJS from '@bcpros/xpi-js';
import { WalletContextNodeValue } from '../context/index';
import { useSliceDispatch, useSliceSelector } from '@store/index';
import { xecReceivedNotificationWebSocket } from '@store/notification/actions';
import {
  WalletPathAddressInfo,
  WalletState,
  WalletStatus,
  WalletStatusNode,
  getAllWalletPaths,
  getSelectedWalletPath,
  getWaletRefreshInterval,
  getWalletHasUpdated,
  getWalletState,
  getWalletStatus,
  getWalletUtxos,
  getWalletUtxosNode,
  setWalletHasUpdated,
  setWalletPaths,
  setWalletRefreshInterval,
  writeWalletStatus,
  writeWalletStatusNode
} from '@store/wallet';
import { getHashArrayFromWallet, getWalletBalanceFromUtxos } from '../utils/cashMethodsNode';
import {
  getTxHistoryChronik,
  getUtxosChronik,
  organizeUtxosByType,
  parseChronikTx_InNode
} from '../utils/chronik-node';
import isEqualIgnoreUndefined from '../utils/comparision';
import { ChronikClientNode, MsgTxClient, Tx_InNode, Utxo_InNode, WsEndpoint_InNode, WsMsgClient } from 'chronik-client';
import _ from 'lodash';
import { useEffect, useState } from 'react';
// @ts-ignore
import { Hash160AndAddress } from '@bcpros/lixi-models/lib/wallet/wallet.model';
import { Account } from '@bcpros/lixi-models/lib/account/account.model';
import { COIN } from '@bcpros/lixi-models/constants/coins/coin';
import { getAllAccounts, getSelectedAccount } from '@store/account';
import useInterval from './useInterval';
import { useXPI } from './useXPI';
import * as wif from 'wif';

// const chronik = new ChronikClient('https://chronik.be.cash/xec');
const websocketConnectedRefreshInterval = 10000;

export const useWalletNode = () => {
  // @todo: use constant
  // and consider to move to redux the neccessary variable

  const [chronikWebsocket, setChronikWebsocket] = useState<WsEndpoint_InNode>(null);
  const [apiError, setApiError] = useState(false);
  const [chronik, setChronik] = useState<ChronikClientNode>(
    new ChronikClientNode(process.env.NEXT_PUBLIC_CHRONIK_URL.split(','))
  );
  const { getXPI } = useXPI();
  const XPI = getXPI();
  const accounts = useSliceSelector(getAllAccounts);
  const walletState = useSliceSelector(getWalletState);
  const walletRefreshInterval = useSliceSelector(getWaletRefreshInterval);
  const walletHasUpdated = useSliceSelector(getWalletHasUpdated);
  const allWalletPaths = useSliceSelector(getAllWalletPaths);
  const selectedWalletPath = useSliceSelector(getSelectedWalletPath);
  const walletUtxos = useSliceSelector(getWalletUtxosNode);
  const dispatch = useSliceDispatch();
  const walletStatus = useSliceSelector(getWalletStatus);
  const selectedAccount = useSliceSelector(getSelectedAccount);

  useEffect(() => {
    if (!selectedAccount) return;

    let accountCoin: string;

    switch (selectedAccount.coin ?? selectedAccount.rootCoin) {
      case COIN.XEC:
        accountCoin = 'xec';
        break;
      default:
        accountCoin = 'xec';
        break;
    }

    setChronik(new ChronikClientNode(process.env.NEXT_PUBLIC_CHRONIK_URL.split(',')));
  }, [selectedAccount]);

  const getWalletPathDetails = async (mnemonic: string, paths: string[]): Promise<WalletPathAddressInfo[]> => {
    const NETWORK = process.env.NEXT_PUBLIC_NETWORK;
    const rootSeedBuffer = await XPI.Mnemonic.toSeed(mnemonic);
    let masterHDNode;

    if (NETWORK === `mainnet`) {
      masterHDNode = XPI.HDNode.fromSeed(rootSeedBuffer);
    } else {
      masterHDNode = XPI.HDNode.fromSeed(rootSeedBuffer, 'testnet');
    }

    const walletPaths: WalletPathAddressInfo[] = [];
    for (const path of paths) {
      const walletPath = await deriveAccount(XPI, {
        masterHDNode,
        path: path
      });
      walletPaths.push(walletPath);
    }

    return walletPaths;
  };

  const getXecWalletPublicKey = async (mnemonic: string): Promise<string> => {
    const rootSeedBuffer: Buffer = await XPI.Mnemonic.toSeed(mnemonic);
    const masterHDNode = XPI.HDNode.fromSeed(rootSeedBuffer);
    const hdPath = `m/44'/1899'/0'/0/0`;
    const childNode = XPI.HDNode.derivePath(masterHDNode, hdPath);
    const publicKey = XPI.HDNode.toPublicKey(childNode).toString('hex');

    return publicKey;
  };

  const deriveAccount = async (XPI: BCHJS, { masterHDNode, path }) => {
    const node = XPI.HDNode.derivePath(masterHDNode, path);
    const cashAddress = XPI.HDNode.toCashAddress(node);
    const hash160 = XPI.Address.toHash160(cashAddress);
    const slpAddress = XPI.SLP.Address.toSLPAddress(cashAddress);
    const xAddress = XPI.HDNode.toXAddress(node);
    const publicKey = XPI.HDNode.toPublicKey(node).toString('hex');
    const walletWif = XPI.HDNode.toWIF(node);
    const { privateKey }: { privateKey: Uint8Array } = wif.decode(walletWif);
    return {
      path,
      xAddress,
      cashAddress,
      slpAddress,
      hash160,
      fundingWif: XPI.HDNode.toWIF(node),
      fundingAddress: XPI.SLP.Address.toSLPAddress(cashAddress),
      legacyAddress: XPI.SLP.Address.toLegacyAddress(cashAddress),
      publicKey,
      privateKey: Buffer.from(privateKey).toString('hex')
    };
  };

  const validateMnemonic = (mnemonic: string, wordlist = XPI.Mnemonic.wordLists().english): boolean => {
    let mnemonicTestOutput;

    try {
      mnemonicTestOutput = XPI.Mnemonic.validate(mnemonic, wordlist);

      if (mnemonicTestOutput === 'Valid mnemonic') {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const syncAccountsToWallets = async (accounts: Account[], walletPaths: WalletPathAddressInfo[]) => {
    const accountsNotInWallets = _.filter(accounts, (account: Account) => {
      return !_.some(walletPaths, (walletPath: WalletPathAddressInfo) => {
        return walletPath?.xAddress === account.address;
      });
    });
    const walletsAlreadySync: WalletPathAddressInfo[] = _.filter(walletPaths, (walletPath: WalletPathAddressInfo) => {
      return _.some(accounts, (account: Account) => {
        return walletPath?.xAddress === account.address;
      });
    });

    // There is an mismatch between accounts and wallets
    if (accountsNotInWallets.length > 0 || walletsAlreadySync.length !== accounts.length) {
      // Then calculate the wallets and dispatch action to save the wallet paths to redux
      const derivedWalletPathsPromises: Array<Promise<WalletPathAddressInfo[]>> = _.map(
        accountsNotInWallets,
        account => {
          switch (account?.coin) {
            case 'XPI':
              return getWalletPathDetails(account.mnemonic, ["m/44'/10605'/0'/0/0"]);
            case 'XEC':
              return getWalletPathDetails(account.mnemonic, ["m/44'/1899'/0'/0/0"]);
          }
        }
      );
      // Calculate the wallet not synced yet
      const walletsPathToSync: WalletPathAddressInfo[] = (await Promise.all(derivedWalletPathsPromises)).flat();
      const walletPaths: WalletPathAddressInfo[] = [...walletsAlreadySync, ...walletsPathToSync];
      dispatch(setWalletPaths(walletPaths));
    }
  };

  const haveUtxosChanged = (utxos: Utxo_InNode[], previousUtxos: Utxo_InNode[]) => {
    // Relevant points for this array comparing exercise
    // https://stackoverflow.com/questions/13757109/triple-equal-signs-return-false-for-arrays-in-javascript-why
    // https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript

    // If this is initial state
    if (utxos === null) {
      // Then make sure to get slpBalancesAndUtxos
      return true;
    }
    // If this is the first time the wallet received utxos
    if (typeof utxos === 'undefined') {
      // Then they have certainly changed
      return true;
    }
    if (typeof previousUtxos === 'undefined') {
      return true;
    }
    // return true for empty array, since this means you definitely do not want to skip the next API call
    if (utxos && utxos.length === 0) {
      return true;
    }

    // If wallet is valid, compare what exists in written wallet state instead of former api call
    const utxosToCompare = previousUtxos;

    const haveChanged = !_.isEqualWith(utxos, utxosToCompare, isEqualIgnoreUndefined);

    // Compare utxo sets
    return haveChanged;
  };

  // Parse chronik ws message for incoming tx notifications
  const processChronikWsMsg = async (msg: WsMsgClient, wallet: WalletState) => {
    // get the message type
    const { type } = msg;

    // For now, only act on "first seen" transactions, as the only logic to happen is first seen notifications
    // Dev note: Other chronik msg types
    // "BlockConnected", arrives as new blocks are found
    // "Confirmed", arrives as subscribed + seen txid is confirmed in a block
    if (type === 'Error') {
      return;
    }

    // If you see a tx from your subscribed addresses added to the mempool, then the wallet utxo set has changed
    // Update it
    dispatch(setWalletRefreshInterval(10));

    // get txid info
    const { txid } = msg as MsgTxClient;

    let incomingTxDetails: Tx_InNode;
    try {
      incomingTxDetails = await chronik.tx(txid);
    } catch (err) {
      // In this case, no notification
      return console.log(`Error in chronik.tx(${txid} while processing an incoming websocket tx`, err);
    }

    // parse tx for notification
    const parsedChronikTx = await parseChronikTx_InNode(XPI, chronik, incomingTxDetails, wallet);

    if (parsedChronikTx && parsedChronikTx.incoming) {
      // Notification
      dispatch(xecReceivedNotificationWebSocket(parsedChronikTx.xecAmount));
    }
  };

  // Chronik websockets
  const initializeWebsocket = async (wallet: WalletState) => {
    console.log(`Initializing websocket connection for wallet ${wallet}`);

    // @todo: previously we have one wallet, now we have multiple
    const hash160Array = getHashArrayFromWallet(wallet);
    if (!wallet || !hash160Array) {
      return setChronikWebsocket(null);
    }

    // Initialize if not in state
    let ws = chronikWebsocket;
    if (ws === null) {
      console.log('start connect chronik websocket');

      try {
        ws = chronik.ws({
          onMessage: (msg: WsMsgClient) => {
            processChronikWsMsg(msg, wallet);
          },
          onReconnect: e => {
            // Fired before a reconnect attempt is made:
            console.log('Reconnecting websocket, disconnection cause: ', e);
          },
          onConnect: e => {
            console.log(`Chronik websocket connected`, e);
            console.log(
              `Websocket connected, adjusting wallet refresh interval to ${websocketConnectedRefreshInterval / 1000}s`
            );
            setWalletRefreshInterval(websocketConnectedRefreshInterval);
          },
          onError: e => {
            console.log('error', e);
          }
        });

        // Wait for websocket to be connected:
        await ws.waitForOpen();
      } catch (e) {
        console.error('useWalletNode - websocket - error: ', e);
      }
    } else {
      /*        
      If the websocket connection is not null, initializeWebsocket was called
      because one of the websocket's dependencies changed

      Update the onMessage method to get the latest dependencies (wallet, fiatPrice)
      */
      ws.onMessage = (msg: WsMsgClient) => {
        processChronikWsMsg(msg, wallet);
      };
    }

    // Check if current subscriptions match current wallet
    let activeSubscriptionsMatchActiveWallet = true;

    const previousWebsocketSubscriptions = ws.subs.scripts;

    // If there are no previous subscriptions, then activeSubscriptionsMatchActiveWallet is certainly false
    if (!previousWebsocketSubscriptions || previousWebsocketSubscriptions.length === 0) {
      activeSubscriptionsMatchActiveWallet = false;
    } else {
      const subscribedHash160Array = previousWebsocketSubscriptions.map(function (subscription) {
        return subscription.payload;
      });
      // Confirm that websocket is subscribed to every address in wallet hash160Array
      for (let i = 0; i < hash160Array.length; i += 1) {
        if (!subscribedHash160Array.includes(hash160Array[i])) {
          activeSubscriptionsMatchActiveWallet = false;
        }
      }
    }

    // If you are already subscribed to the right addresses, exit here
    // You get to this situation if fiatPrice changed but wallet.mnemonic did not
    if (activeSubscriptionsMatchActiveWallet) {
      // Put connected websocket in state
      return setChronikWebsocket(ws);
    }

    // Unsubscribe to any active subscriptions
    if (previousWebsocketSubscriptions && previousWebsocketSubscriptions.length > 0) {
      for (let i = 0; i < previousWebsocketSubscriptions.length; i += 1) {
        const unsubHash160 = previousWebsocketSubscriptions[i].payload;
        ws.unsubscribeFromScript('p2pkh', unsubHash160);
      }
    }

    // Subscribe to addresses of current wallet
    for (let i = 0; i < hash160Array.length; i += 1) {
      ws.subscribeToScript('p2pkh', hash160Array[i]);
    }

    // Put connected websocket in state
    return setChronikWebsocket(ws);
  };

  const update = async (wallet: WalletState) => {
    // Check if walletRefreshInterval is set to 10, i.e. this was called by websocket tx detection
    // If walletRefreshInterval is 10, set it back to the usual refresh rate
    if (walletRefreshInterval === 10) {
      dispatch(setWalletRefreshInterval(websocketConnectedRefreshInterval));
    }
    try {
      if (!wallet || _.isEmpty(wallet.ids) || _.isNil(selectedWalletPath) || _.isNil(selectedAccount)) {
        return;
      }

      const { chronikUtxos, nonSlpUtxos } = await getUtxosByCoin(
        selectedAccount?.rootCoin ?? selectedAccount?.coin ?? COIN.XPI
      );

      // Need to call wToUpdateith wallet as a parameter rather than trusting it is in state, otherwise can sometimes get wallet=false from haveUtxosChanged
      const utxosHaveChanged = haveUtxosChanged(chronikUtxos, walletUtxos);

      // If the utxo set has not changed,
      if (!utxosHaveChanged) {
        // remove api error here; otherwise it will remain if recovering from a rate
        // limit error with an unchanged utxo set
        setApiError(false);
        // then wallet.state has not changed and does not need to be updated
        //console.timeEnd("update");
        return;
      }

      const { chronikTxHistory } = await getTxHistoryChronik(
        chronik,
        XPI,
        wallet,
        0,
        selectedAccount?.rootCoin ?? selectedAccount?.coin
      );

      const newWalletStatus: WalletStatusNode = {
        balances: getWalletBalanceFromUtxos(nonSlpUtxos, selectedAccount?.coin),
        slpBalancesAndUtxos: {
          nonSlpUtxos
        },
        parsedTxHistory: chronikTxHistory,
        utxos: chronikUtxos
      };

      if (!_.isEqual(newWalletStatus, walletStatus)) {
        dispatch(writeWalletStatusNode(newWalletStatus));
      }

      setApiError(false);
    } catch (error) {
      console.log(`Error in update({wallet})`);
      console.log(error);
      // Set this in state so that transactions are disabled until the issue is resolved
      setApiError(true);
      //console.timeEnd("update");
      // Try another endpoint
      console.log(`Trying next API...`);
    }
  };

  const getUtxosByCoin = async (coin: COIN) => {
    const chronikByCoin: ChronikClientNode = new ChronikClientNode(process.env.NEXT_PUBLIC_CHRONIK_URL.split(','));

    let currentCoinAddress = undefined;
    switch (coin) {
      case COIN.XPI:
        currentCoinAddress = selectedWalletPath?.xAddress;
        break;
      case COIN.XEC:
        currentCoinAddress = selectedWalletPath?.cashAddress;
        break;
      case COIN.XRG:
        currentCoinAddress = selectedWalletPath?.cashAddress;
        break;
      default:
        currentCoinAddress = selectedWalletPath?.xAddress;
        break;
    }

    const hash160AndAddressObjArray: Hash160AndAddress[] = [selectedWalletPath].map(item => {
      return {
        address: currentCoinAddress ?? item.xAddress,
        hash160: item.hash160
      };
    });
    const chronikUtxos = await getUtxosChronik(
      selectedAccount?.coin == coin ? chronik : chronikByCoin,
      hash160AndAddressObjArray
    );

    const { nonSlpUtxos } = organizeUtxosByType(chronikUtxos);

    return { chronikUtxos, nonSlpUtxos };
  };

  // Update wallet according to defined interval
  useInterval(async () => {
    const wallet = walletState;

    update(wallet).finally(() => {
      if (!walletHasUpdated) {
        dispatch(setWalletHasUpdated(true));
      }
    });
  }, walletRefreshInterval);
  /*
    Use wallet.mnemonic as the useEffect parameter here because we 
    want to run initializeWebsocket(wallet, fiatPrice) when a new unique wallet
    is selected, not when the active wallet changes state
    */
  useEffect(() => {
    (async () => {
      await initializeWebsocket(walletState);
      // dispatch(activateWallet(walletState.mnemonic));
    })();
  }, [walletState.mnemonic]);

  useEffect(() => {
    syncAccountsToWallets(accounts, allWalletPaths);
  }, []);

  return {
    XPI,
    chronik,
    deriveAccount,
    getWalletPathDetails,
    validateMnemonic,
    getUtxosByCoin,
    getXecWalletPublicKey
  } as WalletContextNodeValue;
};
