import { COIN } from '@bcpros/lixi-models/constants/coins/coin';
import { coinInfo } from '@bcpros/lixi-models/constants/coins/coin-info';
import BCHJS from '@bcpros/xpi-js';
import * as utxolib from '@bitgo/utxo-lib';
import { WalletPathAddressInfo, WalletState } from '@store/wallet';
import BigNumber from 'bignumber.js';
import bs58 from 'bs58';
import { Utxo_InNode } from 'chronik-client';
import * as cashaddr from 'ecashaddrjs';
import * as ergonCashaddr from 'ergonaddrjs';
import { createSharedKey, decrypt, encrypt } from './encryption';

export type TxInputObj = {
  txBuilder: any;
  totalInputUtxoValue: BigNumber;
  inputUtxos: Array<Utxo_InNode & { address: string }>;
  txFee: number;
};

export const fromLegacyDecimals = (amount, cashDecimals = coinInfo[COIN.XEC].cashDecimals) => {
  // Input 0.00000546 BCH
  // Output 5.46 XEC or 0.00000546 BCH, depending on currency.cashDecimals
  const amountBig = new BigNumber(amount);
  const conversionFactor = new BigNumber(10 ** (8 - cashDecimals));
  const amountSmallestDenomination = amountBig.times(conversionFactor).toNumber();
  return amountSmallestDenomination;
};

export const fromSmallestDenomination = (amount, cashDecimals = coinInfo[COIN.XEC].cashDecimals) => {
  const amountBig = new BigNumber(amount);
  const multiplier = new BigNumber(10 ** (-1 * cashDecimals));
  const amountInBaseUnits = amountBig.times(multiplier);
  return amountInBaseUnits.toNumber();
};

export const toSmallestDenomination = (sendAmount: BigNumber, cashDecimals = coinInfo[COIN.XEC].cashDecimals) => {
  // Replace the BCH.toSatoshi method with an equivalent function that works for arbitrary decimal places
  // Example, for an 8 decimal place currency like Bitcoin
  // Input: a BigNumber of the amount of Bitcoin to be sent
  // Output: a BigNumber of the amount of satoshis to be sent, or false if input is invalid

  // Validate
  // Input should be a BigNumber with no more decimal places than cashDecimals
  const isValidSendAmount = BigNumber.isBigNumber(sendAmount) && sendAmount.dp() <= cashDecimals;
  if (!isValidSendAmount) {
    return false;
  }
  const conversionFactor = new BigNumber(10 ** cashDecimals);
  const sendAmountSmallestDenomination = sendAmount.times(conversionFactor);
  return sendAmountSmallestDenomination;
};

export const fromCoinToSatoshis = (
  sendAmount: BigNumber,
  cashDecimals = coinInfo[COIN.XPI].cashDecimals
): BigNumber | false => {
  const isValidSendAmount = BigNumber.isBigNumber(sendAmount) && sendAmount.dp() <= cashDecimals;
  if (!isValidSendAmount) {
    return false;
  }
  const conversionFactor = new BigNumber(10 ** cashDecimals);
  const sendAmountSmallestDenomination = sendAmount.times(conversionFactor);
  return sendAmountSmallestDenomination;
};

export const fromSatoshisToCoin = (amount, cashDecimals = coinInfo[COIN.XPI].cashDecimals): BigNumber => {
  const amountBig = new BigNumber(amount);
  const multiplier = new BigNumber(10 ** (-1 * cashDecimals));
  const amountInBaseUnits = amountBig.times(multiplier);
  return amountInBaseUnits;
};

export const parseXpiSendValue = (
  isOneToMany: boolean,
  singleSendValue: Nullable<string>,
  destinationAddressAndValueArray: Nullable<Array<string>>
): BigNumber => {
  let value = new BigNumber(0);

  try {
    if (isOneToMany) {
      // this is a one to many transaction
      if (!destinationAddressAndValueArray || !destinationAddressAndValueArray.length) {
        throw new Error('Invalid destinationAddressAndValueArray');
      }
      const arrayLength = destinationAddressAndValueArray.length;
      for (let i = 0; i < arrayLength; i++) {
        // add the total value being sent in this array of recipients
        // each array row is: 'eCash address, send value'
        value = BigNumber.sum(value, new BigNumber(destinationAddressAndValueArray[i].split(',')[1]));
      }
    } else {
      // this is a one to one XEC transaction then check singleSendValue
      // note: one to many transactions won't be sending a singleSendValue param

      if (!singleSendValue) {
        throw new Error('Invalid singleSendValue');
      }

      value = new BigNumber(singleSendValue);
    }
    // If user is attempting to send an aggregate value that is less than minimum accepted by the backend
    if (value.lt(new BigNumber(fromSmallestDenomination(coinInfo[COIN.XPI].dustSats).toString()))) {
      // Throw the same error given by the backend attempting to broadcast such a tx
      throw new Error('dust');
    }
  } catch (err) {
    console.log('Error in parseXpiSendValue: ' + err);
    throw err;
  }
  return value;
};

export const getByteCountEscrow = (
  p2shInputCount: number,
  p2pkhOutputCount: number,
  redeemScriptSize: number
): number => {
  // Simplifying bch-js function for P2PKH txs only, as this is all Cashtab supports for now
  // https://github.com/Permissionless-Software-Foundation/bch-js/blob/master/src/bitcoincash.js#L408
  /*
  const types = {
      inputs: {            
          current scriptsig we create have form: spenderPK, oracleSig, oraclePK, OP_1|2|..., redeemScript
          PK: 33 bytes, Sig: 73 bytes, OP_?: 1 byte, redeemScript: ? bytes 
          P2SH: 33 * 2 + 73 + 1 + sizeRedeemScript
      },
      outputs: {
          P2PKH: 34 * 4,
      },
  };
  */

  const inputCount = new BigNumber(p2shInputCount);
  const outputCount = new BigNumber(p2pkhOutputCount);

  const inputWeight = new BigNumber((33 * 2 + 73 + 1 + redeemScriptSize) * 4); //*4 because P2SH not SegWit
  const outputWeight = new BigNumber(34 * 4);
  const nonSegwitWeightConstant = new BigNumber(10 * 4);
  let totalWeight = new BigNumber(0);
  totalWeight = totalWeight
    .plus(inputCount.times(inputWeight))
    .plus(outputCount.times(outputWeight))
    .plus(nonSegwitWeightConstant);
  const byteCount = totalWeight.div(4).integerValue(BigNumber.ROUND_CEIL);

  return Number(byteCount);
};

export const calcFeeEscrow = (
  utxosLength: number,
  p2pkhOutputNumber = 2,
  satoshisPerByte = 2.01,
  opReturnLength = 0,
  scriptByteSize: number
) => {
  const byteCount = getByteCountEscrow(utxosLength, p2pkhOutputNumber, scriptByteSize);

  let opReturnOutputByteLength = opReturnLength;
  if (opReturnLength) {
    opReturnOutputByteLength += 8 + 1;
  }
  const txFee = Math.ceil(satoshisPerByte * (byteCount + opReturnOutputByteLength));
  return txFee;
};

export const getByteCount = (p2pkhInputCount: number, p2pkhOutputCount: number): number => {
  // Simplifying bch-js function for P2PKH txs only, as this is all Cashtab supports for now
  // https://github.com/Permissionless-Software-Foundation/bch-js/blob/master/src/bitcoincash.js#L408
  /*
  const types = {
      inputs: {            
          'P2PKH': 148 * 4,
      },
      outputs: {
          P2PKH: 34 * 4,
      },
  };
  */

  const inputCount = new BigNumber(p2pkhInputCount);
  const outputCount = new BigNumber(p2pkhOutputCount);
  const inputWeight = new BigNumber(148 * 4);
  const outputWeight = new BigNumber(34 * 4);
  const nonSegwitWeightConstant = new BigNumber(10 * 4);
  let totalWeight = new BigNumber(0);
  totalWeight = totalWeight
    .plus(inputCount.times(inputWeight))
    .plus(outputCount.times(outputWeight))
    .plus(nonSegwitWeightConstant);
  const byteCount = totalWeight.div(4).integerValue(BigNumber.ROUND_CEIL);

  return Number(byteCount);
};

export const calcFee = (
  utxos: Array<Utxo_InNode>,
  p2pkhOutputNumber = 2,
  satoshisPerByte = coinInfo[COIN.XPI].defaultFee,
  opReturnLength = 0
) => {
  const byteCount = getByteCount(utxos.length, p2pkhOutputNumber);

  let opReturnOutputByteLength = opReturnLength;
  if (opReturnLength) {
    opReturnOutputByteLength += 8 + 1;
  }
  const txFee = Math.ceil(satoshisPerByte * (byteCount + opReturnOutputByteLength));
  return txFee;
};

export const generateTxInput = (
  XPI: BCHJS,
  isOneToMany: boolean,
  utxos: Array<Utxo_InNode & { address: string }>,
  txBuilder: any,
  destinationAddressAndValueArray: Array<any>,
  satoshisToSend,
  feeInSatsPerByte
): TxInputObj => {
  const inputUtxos = [];
  let txFee = 0;
  let totalInputUtxoValue = new BigNumber(0);
  try {
    if (
      !XPI ||
      (isOneToMany && !destinationAddressAndValueArray) ||
      !utxos ||
      !txBuilder ||
      !satoshisToSend ||
      !feeInSatsPerByte
    ) {
      throw new Error('Invalid tx input parameter');
    }

    // A normal tx will have 2 outputs, destination and change
    // A one to many tx will have n outputs + 2 change output, where n is the number of recipients, 1 destination and 1 change
    const txOutputs = isOneToMany ? destinationAddressAndValueArray.length + 2 : 2;
    for (let i = 0; i < utxos.length; i++) {
      const utxo = utxos[i];
      totalInputUtxoValue = totalInputUtxoValue.plus(utxo.value);
      const vout = utxo.outpoint.outIdx;
      const txid = utxo.outpoint.txid;
      // add input with txid and index of vout
      txBuilder.addInput(txid, vout);

      inputUtxos.push(utxo);
      txFee = calcFee(inputUtxos, txOutputs, feeInSatsPerByte);

      if (totalInputUtxoValue.minus(satoshisToSend).minus(txFee).gte(0)) {
        break;
      }
    }
  } catch (err) {
    console.log(`generateTxInput() error: ` + err);
    throw err;
  }
  const txInputObj: TxInputObj = {
    txBuilder,
    totalInputUtxoValue,
    inputUtxos,
    txFee
  };

  return txInputObj;
};

export const generateTxOutput = (
  XPI: BCHJS,
  isOneToMany: boolean,
  singleSendValue: Nullable<BigNumber>,
  satoshisToSend: Nullable<BigNumber>,
  totalInputUtxoValue: BigNumber,
  destinationAddress: Nullable<string>,
  destinationAddressAndValueArray: Nullable<Array<string>>,
  changeAddress: Nullable<string>,
  txFee: number,
  txBuilder: any
) => {
  try {
    if (
      !XPI ||
      (isOneToMany && !destinationAddressAndValueArray) ||
      (!isOneToMany && !destinationAddress && !singleSendValue) ||
      !changeAddress ||
      !satoshisToSend ||
      !totalInputUtxoValue ||
      !txFee ||
      !txBuilder
    ) {
      throw new Error('Invalid tx input parameter');
    }

    // amount to send back to the remainder address.
    const remainder = new BigNumber(totalInputUtxoValue).minus(satoshisToSend).minus(txFee);
    if (remainder.lt(0)) {
      throw new Error(`Insufficient funds`);
    }

    if (isOneToMany) {
      // for one to many mode, add the multiple outputs from the array
      const arrayLength = destinationAddressAndValueArray.length;
      for (let i = 0; i < arrayLength; i++) {
        // add each send tx from the array as an output
        const outputAddress = destinationAddressAndValueArray[i].split(',')[0];
        const outputValue = new BigNumber(destinationAddressAndValueArray[i].split(',')[1]);
        txBuilder.addOutput(
          outputAddress,
          parseInt(fromCoinToSatoshis(outputValue, coinInfo[COIN.XPI].cashDecimals).toString())
        );
      }
    } else {
      // for one to one mode, add output w/ single address and amount to send
      txBuilder.addOutput(
        destinationAddress,
        parseInt(fromCoinToSatoshis(singleSendValue, coinInfo[COIN.XPI].cashDecimals).toString())
      );
    }

    // if a remainder exists, return to change address as the final output
    if (remainder.gte(new BigNumber(coinInfo[COIN.XPI].dustSats))) {
      txBuilder.addOutput(changeAddress, parseInt(remainder.toString()));
    }
  } catch (err) {
    console.log('Error in generateTxOutput(): ' + err);
    throw err;
  }

  return txBuilder;
};

export const signUtxosByAddress = (
  XPI: BCHJS,
  inputUtxos: Array<Utxo_InNode & { address: string }>,
  walletPaths: WalletPathAddressInfo[],
  txBuilder
) => {
  for (let i = 0; i < inputUtxos.length; i++) {
    const utxo = inputUtxos[i];
    const utxoEcPair = XPI.ECPair.fromWIF(walletPaths.filter(path => path.xAddress === utxo.address).pop().fundingWif);

    txBuilder.sign(i, utxoEcPair, undefined, txBuilder.hashTypes.SIGHASH_ALL, utxo.value);
  }

  return txBuilder;
};

export const signAndBuildTx = (
  XPI: BCHJS,
  inputUtxos: Array<Utxo_InNode & { address: string }>,
  txBuilder: any,
  walletPaths: WalletPathAddressInfo[]
): string => {
  if (
    !XPI ||
    !inputUtxos ||
    inputUtxos.length === 0 ||
    !txBuilder ||
    !walletPaths ||
    walletPaths.length === 0 ||
    // txBuilder.transaction.tx.ins is empty until the inputUtxos are signed
    txBuilder.transaction.tx.outs.length === 0
  ) {
    throw new Error('Invalid buildTx parameter');
  }

  // Sign each XEC UTXO being consumed and refresh transactionBuilder
  txBuilder = signUtxosByAddress(XPI, inputUtxos, walletPaths, txBuilder);

  let hex;
  try {
    // build tx
    const tx = txBuilder.build();
    // output rawhex
    hex = tx.toHex();
  } catch (err) {
    throw new Error('Transaction build failed');
  }
  return hex;
};

/*
 * Generates an OP_RETURN script to reflect the various send XPI permutations
 * involving messaging, encryption.
 *
 * Returns the final encoded script object
 */
export const generateOpReturnScript = (
  XPI: BCHJS,
  optionalOpReturnMsg: string,
  encryptionFlag: boolean,
  encryptedEj: Uint8Array
): Buffer => {
  // encrypted mesage is mandatory when encryptionFlag is true
  if (!XPI || (encryptionFlag && !optionalOpReturnMsg)) {
    throw new Error('Invalid OP RETURN script input');
  }

  let script;

  try {
    if (encryptionFlag) {
      // if the user has opted to encrypt this message
      script = [
        XPI.Script.opcodes.OP_RETURN, // 6a
        Buffer.from(coinInfo[COIN.XPI].opReturn.appPrefixesHex.lotusChatEncrypted, 'hex'), // 03030303
        Buffer.from(encryptedEj)
      ];
    } else if (optionalOpReturnMsg) {
      // this is an un-encrypted message
      script = [
        XPI.Script.opcodes.OP_RETURN, // 6a
        Buffer.from(coinInfo[COIN.XPI].opReturn.appPrefixesHex.lotusChat, 'hex'), // 02020202
        Buffer.from(optionalOpReturnMsg)
      ];
    }
    const data: Buffer = XPI.Script.encode(script);
    return data;
  } catch (err) {
    console.log('Error in generateOpReturnScript(): ' + err);
    throw err;
  }
};

export const getChangeAddressFromInputUtxos = (
  XPI: BCHJS,
  inputUtxos: Array<Utxo_InNode & { address: string }>
): string => {
  if (!XPI || !inputUtxos) {
    throw new Error('Invalid getChangeAddressFromWallet input parameter');
  }

  // Assume change address is input address of utxo at index 0
  let changeAddress;

  // Validate address
  try {
    changeAddress = inputUtxos[0].address;
    const isValidXAddress = XPI.Address.isXAddress(changeAddress);
    if (!isValidXAddress) {
      throw new Error('Invalid change address');
    }
  } catch (err) {
    throw new Error('Invalid input utxo');
  }
  return changeAddress;
};

export const getDustXPI = () => {
  return (coinInfo[COIN.XPI].dustSats / 10 ** coinInfo[COIN.XPI].cashDecimals).toString();
};

export const formatBalance = x => {
  try {
    const balanceInParts = x.toString().split('.');
    balanceInParts[0] = balanceInParts[0].replace(/\B(?=(\d{2})+(?!\d))/g, '');
    if (balanceInParts.length > 1) {
      balanceInParts[1] = balanceInParts[1].slice(0, 2);
    }
    return balanceInParts.join('.');
  } catch (err) {
    console.log(`Error in formatBalance for ${x}`);
    console.log(err);
    return x;
  }
};

export const normalizeBalance = slpBalancesAndUtxos => {
  const totalBalanceInSatoshis = slpBalancesAndUtxos.nonSlpUtxos.reduce(
    (previousBalance, utxo) => previousBalance + utxo.value,
    0
  );
  return {
    totalBalanceInSatoshis,
    totalBalance: fromSmallestDenomination(totalBalanceInSatoshis)
  };
};

export const getWalletBalanceFromUtxos = (nonSlpUtxos: Utxo_InNode[], coin = COIN.XEC) => {
  const totalBalanceInSatoshis = nonSlpUtxos.reduce(
    (previousBalance, utxo) => previousBalance.plus(new BigNumber(utxo.value)),
    new BigNumber(0)
  );
  return {
    totalBalanceInSatoshis: totalBalanceInSatoshis.toString(),
    totalBalance: fromSmallestDenomination(
      totalBalanceInSatoshis,
      coin === COIN.XRG ? coinInfo[COIN.XRG].microCashDecimals : coinInfo[coin].cashDecimals
    ).toString()
  };
};

export const isValidStoredWallet = walletStateFromStorage => {
  return (
    typeof walletStateFromStorage === 'object' &&
    'state' in walletStateFromStorage &&
    typeof walletStateFromStorage.state === 'object' &&
    'balances' in walletStateFromStorage.state &&
    'utxos' in walletStateFromStorage.state &&
    'hydratedUtxoDetails' in walletStateFromStorage.state &&
    'slpBalancesAndUtxos' in walletStateFromStorage.state &&
    'tokens' in walletStateFromStorage.state
  );
};

export const getWalletStateMethod = wallet => {
  if (!wallet) {
    return {
      balance: 0,
      parsedTxHistory: [],
      utxos: []
    };
  }

  return {
    ...wallet,
    balance: fromSmallestDenomination(wallet?.balance || 0)
  };
};

export const getUtxoWif = (
  utxo: Utxo_InNode & { address: string },
  walletPaths: Array<WalletPathAddressInfo>,
  selectedCoin = COIN.XPI
) => {
  if (!walletPaths) {
    throw new Error('Invalid wallet parameter');
  }
  let wif = '';
  switch (selectedCoin) {
    case COIN.XEC:
    case COIN.XRG:
      wif = walletPaths.filter(acc => acc.cashAddress === utxo.address).pop().fundingWif;
      break;
    case COIN.XPI:
      wif = walletPaths.filter(acc => acc.xAddress === utxo.address).pop().fundingWif;
      break;
    default:
      wif = walletPaths.filter(acc => acc.xAddress === utxo.address).pop().fundingWif;
      break;
  }
  return wif;
};

export const getHashArrayFromWallet = (wallet: WalletState): string[] => {
  if (!wallet || !wallet?.entities) {
    return [];
  }
  const hash160Array = Object.entries(wallet.entities).map(([key, value]) => {
    return (value as WalletPathAddressInfo)?.hash160;
  });
  return hash160Array;
};

export const getHashFromWallet = (wallet: WalletState): string => {
  if (!wallet || !wallet?.entities) {
    return '';
  }

  let selectedHash160 = '';
  Object.entries(wallet.entities).map(([key, value]) => {
    if (key === wallet.selectedWalletPath) selectedHash160 = value.hash160;
  });
  return selectedHash160;
};

export const getSelectedWallet = (wallet: WalletState): WalletPathAddressInfo => {
  if (!wallet || !wallet?.entities) {
    return;
  }

  let selectedWallet: WalletPathAddressInfo;
  Object.entries(wallet.entities).map(([key, value]) => {
    if (key === wallet.selectedWalletPath) selectedWallet = value;
  });
  return selectedWallet;
};

export const isActiveWebsocket = ws => {
  // Return true if websocket is connected and subscribed
  // Otherwise return false
  return (
    ws !== null &&
    ws &&
    '_ws' in ws &&
    'readyState' in ws._ws &&
    ws._ws.readyState === 1 &&
    '_subs' in ws &&
    ws._subs.length > 0
  );
};

/**
 * Parse the OP_RETURN data of the output
 * @param hexStr The hex string of the output scriptpubkey
 * @returns Array contains transaction type and message's hex
 */
export function parseOpReturn(hexStr: string): Array<string> | false {
  if (
    !hexStr ||
    typeof hexStr !== 'string' ||
    hexStr.substring(0, 2) !== coinInfo[COIN.XPI].opReturn.opReturnPrefixHex
  ) {
    return false;
  }

  hexStr = hexStr.slice(2); // remove the first byte i.e. 6a
  /*
   * @Return: resultArray is structured as follows:
   *  resultArray[0] is the transaction type i.e. eToken prefix, sendlotus prefix, external message itself if unrecognized prefix
   *  resultArray[1] is the actual sendlotus message or the 2nd part of an external message
   *  resultArray[2 - n] are the additional messages for future protcols
   */
  const resultArray = [];
  let message = '';
  let hexStrLength = hexStr.length;

  for (let i = 0; hexStrLength !== 0; i++) {
    // part 1: check the preceding byte value for the subsequent message
    const byteValue = hexStr.substring(0, 2);
    let msgByteSize = 0;
    if (byteValue === coinInfo[COIN.XPI].opReturn.opPushDataOne) {
      // if this byte is 4c then the next byte is the message byte size - retrieve the message byte size only
      msgByteSize = parseInt(hexStr.substring(2, 4), 16); // hex base 16 to decimal base 10
      hexStr = hexStr.slice(4); // strip the 4c + message byte size info
    } else {
      // take the byte as the message byte size
      msgByteSize = parseInt(hexStr.substring(0, 2), 16); // hex base 16 to decimal base 10
      hexStr = hexStr.slice(2); // strip the message byte size info
    }

    // part 2: parse the subsequent message based on bytesize
    const msgCharLength = 2 * msgByteSize;
    message = hexStr.substring(0, msgCharLength);
    if (i === 0 && message === coinInfo[COIN.XPI].opReturn.appPrefixesHex.eToken) {
      // add the extracted eToken prefix to array then exit loop
      resultArray[i] = coinInfo[COIN.XPI].opReturn.appPrefixesHex.eToken;
      break;
    } else if (i === 0 && message === coinInfo[COIN.XPI].opReturn.appPrefixesHex.lotusChat) {
      // add the extracted Sendlotus prefix to array
      resultArray[i] = coinInfo[COIN.XPI].opReturn.appPrefixesHex.lotusChat;
    } else if (i === 0 && message === coinInfo[COIN.XPI].opReturn.appPrefixesHex.lotusChatEncrypted) {
      // add the Sendlotus encryption prefix to array
      resultArray[i] = coinInfo[COIN.XPI].opReturn.appPrefixesHex.lotusChatEncrypted;
    } else {
      // this is either an external message or a subsequent sendlotus message loop to extract the message
      resultArray[i] = message;
    }

    // strip out the parsed message
    hexStr = hexStr.slice(msgCharLength);
    hexStrLength = hexStr.length;
  }

  return resultArray;
}

export const encryptOpReturnMsg = (
  privateKeyWIF: string,
  recipientPubKeyHex: string,
  plainTextMsg: string
): Uint8Array => {
  let encryptedMsg;
  try {
    const sharedKey = createSharedKey(privateKeyWIF, recipientPubKeyHex);
    encryptedMsg = encrypt(sharedKey, Uint8Array.from(Buffer.from(plainTextMsg)));
  } catch (error) {
    console.log('ENCRYPTION ERROR', error);
    throw error;
  }

  return encryptedMsg;
};

export const decryptOpReturnMsg = async (opReturnMsgHex: string, privateKeyWIF: string, publicKeyHex: string) => {
  try {
    const sharedKey = createSharedKey(privateKeyWIF, publicKeyHex);
    const decryptedMsg = decrypt(sharedKey, Uint8Array.from(Buffer.from(opReturnMsgHex, 'hex')));
    return {
      success: true,
      decryptedMsg
    };
  } catch (error) {
    console.log('DECRYPTION ERROR', error);
    return {
      success: false,
      error
    };
  }
};

export const getAddressesOfWallet = wallet => {
  const addresses = [];
  if (wallet) {
    if (wallet.Path10605 && wallet.Path10605.xAddress) {
      addresses.push(wallet.Path10605.xAddress);
    }
    if (wallet.Path1899 && wallet.Path1899.xAddress) {
      addresses.push(wallet.Path1899.xAddress);
    }
    if (wallet.Path899 && wallet.Path899.xAddress) {
      addresses.push(wallet.Path899.xAddress);
    }
  }

  return addresses;
};

export const isValidCoinAddress = (coin = COIN.XEC, addr: string) => {
  /* 
  Returns true for a valid coin address

  Valid coin address:
  - May or may not have prefix `coin:`
  - Checksum must validate for prefix `coin:`
  
  An eToken address is not considered a valid coin address
  */

  if (!addr) {
    return false;
  }

  const prefixCoin = `${coinInfo[coin].name.toLowerCase()}`;
  let isValidCoinAddress;
  let isPrefixedAddress;

  // Check for possible prefix
  if (addr.includes(':')) {
    // Test for 'ecash:' prefix
    isPrefixedAddress = addr.slice(0, 6) === `${prefixCoin}:`;
    // Any address including ':' that doesn't start explicitly with 'coin:' is invalid
    if (!isPrefixedAddress) {
      isValidCoinAddress = false;
      return isValidCoinAddress;
    }
  } else {
    isPrefixedAddress = false;
  }

  // If no prefix, assume it is checksummed for an coin: prefix
  const testCoinAddr = isPrefixedAddress ? addr : `${prefixCoin}:${addr}`;

  try {
    let decoded;
    switch (coin) {
      case COIN.XEC:
        decoded = cashaddr.decode(testCoinAddr, false);
        break;
      case COIN.XRG:
        decoded = ergonCashaddr.decode(testCoinAddr);
        break;
    }
    if (decoded.prefix === prefixCoin) {
      isValidCoinAddress = true;
    }
  } catch (err) {
    isValidCoinAddress = false;
  }
  return isValidCoinAddress;
};

export function cashaddrToHash160(addr: string) {
  try {
    // decode address hash
    const { hash } = cashaddr.decode(addr, false);
    // encode the address hash to legacy format (bitcoin)
    // becauase chronikReady is false then hash will be UInt8Array
    const legacyAdress = bs58.encode(hash as Uint8Array);
    // convert legacy to hash160
    const addrHash160 = Buffer.from(bs58.decode(legacyAdress)).toString('hex');
    return addrHash160;
  } catch (err) {
    console.log('Error converting address to hash160');
    throw err;
  }
}

export const sumOneToManyXec = (destinationAddressAndValueArray: any) => {
  return destinationAddressAndValueArray.reduce((prev: any, curr: any) => {
    return parseFloat(prev) + parseFloat(curr.split(',')[1]);
  }, 0);
};

export const generateXecTxInput = (
  isOneToMany: boolean,
  utxos: Array<Utxo_InNode & { address: string }>,
  txBuilder: any,
  destinationAddressAndValueArray: Array<any> | null,
  satoshisToSend: any,
  feeInSatsPerByte: any,
  opReturnByteCount: any
) => {
  let txInputObj: any = {};
  const inputUtxos = [];
  let txFee = 0;
  let totalInputUtxoValue = new BigNumber(0);
  try {
    if (
      (isOneToMany && !destinationAddressAndValueArray) ||
      !utxos ||
      !txBuilder ||
      !satoshisToSend ||
      !feeInSatsPerByte
    ) {
      throw new Error('Invalid tx input parameter');
    }

    // A normal tx will have 2 outputs, destination and change
    // A one to many tx will have n outputs + 1 change output, where n is the number of recipients
    const txOutputs = isOneToMany ? destinationAddressAndValueArray!.length + 1 : 2;
    for (let i = 0; i < utxos.length; i++) {
      const utxo = utxos[i];
      totalInputUtxoValue = totalInputUtxoValue.plus(utxo.value);
      const vout = utxo.outpoint.outIdx;
      const txid = utxo.outpoint.txid;
      // add input with txid and index of vout
      txBuilder.addInput(txid, vout);

      inputUtxos.push(utxo);
      txFee = calcFee(inputUtxos, txOutputs, feeInSatsPerByte, opReturnByteCount);

      if (totalInputUtxoValue.minus(satoshisToSend).minus(txFee).gte(0)) {
        break;
      }
    }
  } catch (err) {
    console.log(`generateTxInput() error: ` + err);
    throw err;
  }
  txInputObj.txBuilder = txBuilder;
  txInputObj.totalInputUtxoValue = totalInputUtxoValue;
  txInputObj.inputUtxos = inputUtxos;
  txInputObj.txFee = txFee;
  return txInputObj;
};

export const generateXecTxOutput = (
  isOneToMany: boolean,
  singleSendValue: Nullable<BigNumber>,
  satoshisToSend: Nullable<BigNumber>,
  totalInputUtxoValue: BigNumber,
  destinationAddress: Nullable<string>,
  destinationAddressAndValueArray: Nullable<Array<string>>,
  changeAddress: Nullable<string>,
  txFee: number,
  txBuilder: any
) => {
  try {
    if (
      (isOneToMany && !destinationAddressAndValueArray) ||
      (!isOneToMany && !destinationAddress && !singleSendValue) ||
      !changeAddress ||
      !satoshisToSend ||
      !totalInputUtxoValue ||
      !txFee ||
      !txBuilder
    ) {
      throw new Error('Invalid tx input parameter');
    }

    // amount to send back to the remainder address.
    const remainder = new BigNumber(totalInputUtxoValue).minus(satoshisToSend).minus(txFee);

    if (remainder.lt(0)) {
      throw new Error(`Insufficient funds`);
    }

    if (isOneToMany) {
      // for one to many mode, add the multiple outputs from the array
      let arrayLength = destinationAddressAndValueArray!.length;
      for (let i = 0; i < arrayLength; i++) {
        // add each send tx from the array as an output
        let outputAddress = destinationAddressAndValueArray![i].split(',')[0];
        let outputValue = new BigNumber(destinationAddressAndValueArray![i].split(',')[1]);
        txBuilder.addOutput(
          cashaddr.toLegacy(outputAddress),
          parseInt(fromCoinToSatoshis(outputValue, coinInfo[COIN.XEC].cashDecimals).toString())
        );
      }
    } else {
      // for one to one mode, add output w/ single address and amount to send
      txBuilder.addOutput(
        cashaddr.toLegacy(destinationAddress),
        parseInt(fromCoinToSatoshis(singleSendValue, coinInfo[COIN.XEC].cashDecimals).toString())
      );
    }

    // if a remainder exists, return to change address as the final output
    if (remainder.gte(new BigNumber(coinInfo[COIN.XEC].etokenSats))) {
      txBuilder.addOutput(cashaddr.toLegacy(changeAddress), parseInt(remainder.toString()));
    }
  } catch (err) {
    console.log('Error in generateTxOutput(): ' + err);
    throw err;
  }

  return txBuilder;
};

export const signXecUtxosByAddress = (inputUtxos: any, wallet: any, txBuilder: any) => {
  for (let i = 0; i < inputUtxos.length; i++) {
    const utxo = inputUtxos[i];
    const wif = wallet.filter((path: any) => path.cashAddress === utxo.address).pop().fundingWif;

    const utxoECPair = utxolib.ECPair.fromWIF(wif, utxolib.networks.ecash);

    // Specify hash type
    // This should be handled at the utxo-lib level, pending latest published version
    const hashTypes = {
      SIGHASH_ALL: 0x01,
      SIGHASH_FORKID: 0x40
    };

    txBuilder.sign(
      i, // vin
      utxoECPair, // keyPair
      undefined, // redeemScript
      hashTypes.SIGHASH_ALL | hashTypes.SIGHASH_FORKID, // hashType
      parseInt(utxo.value) // value
    );
  }

  return txBuilder;
};

export const signAndBuildXecTx = (inputUtxos: any, txBuilder: any, wallet: any) => {
  if (!inputUtxos || inputUtxos.length === 0 || !txBuilder || !wallet) {
    throw new Error('Invalid buildTx parameter');
  }

  // Sign each XEC UTXO being consumed and refresh transactionBuilder
  txBuilder = signXecUtxosByAddress(inputUtxos, wallet, txBuilder);

  let hex;
  try {
    // build tx
    const tx = txBuilder.build();
    // output rawhex
    hex = tx.toHex();
  } catch (err) {
    throw new Error('Transaction build failed');
  }
  return hex;
};

export const getChangeAddressFromInputUtxosXec = (inputUtxos: any, wallet: any): string => {
  if (!inputUtxos || !wallet) {
    throw new Error('Invalid getChangeAddressFromWallet input parameter');
  }

  // Assume change address is input address of utxo at index 0
  const { type, hash } = cashaddr.decode(inputUtxos[0].address, false);
  const changeAddress = cashaddr.encode('ecash', type, hash);

  // Validate address
  try {
    const valid = isValidCoinAddress(COIN.XEC, changeAddress);

    if (!valid) {
      throw new Error('Invalid change address');
    }
  } catch (err) {
    throw new Error('Invalid input utxo');
  }
  return changeAddress;
};

export const validateCoinAmount = (value: string, balances: number, coin: COIN): boolean => {
  if (!value) return false;

  //check if value is number;
  if (isNaN(parseFloat(value))) return false;

  //check if value is positive number
  if (parseFloat(value) <= 0) return false;

  //check if balance is smaller than value
  const cashDecimals = coin === COIN.XRG ? coinInfo[coin].microCashDecimals : coinInfo[coin].cashDecimals;
  if (fromSmallestDenomination(balances, cashDecimals) <= parseFloat(value)) return false;

  return true;
};
