/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * yarn graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../generated/types.generated';

import { PostFieldsFragmentDoc } from './posts.generated';
import { api } from '@store/baseApi';
export type CreatePollMutationVariables = Types.Exact<{
  input: Types.CreatePollInput;
}>;

export type CreatePollMutation = {
  __typename?: 'Mutation';
  createPoll: {
    __typename?: 'Post';
    id: string;
    content: string;
    accountId: number;
    pageId?: string | null;
    tokenId?: string | null;
    repostCount: number;
    totalComments: number;
    commentableId?: string | null;
    createdAt: any;
    updatedAt: any;
    followPostOwner?: boolean | null;
    followedPage?: boolean | null;
    followedToken?: boolean | null;
    bookmarkableId?: string | null;
    isBookmarked?: boolean | null;
    originalLanguage?: string | null;
    danaViewScore?: number | null;
    burnedByOthers?: boolean | null;
    account: {
      __typename?: 'Account';
      address: string;
      hash160?: string | null;
      publicKey?: string | null;
      id: number;
      name: string;
      avatar?: string | null;
      createCommentFee?: string | null;
      telegramUsername?: string | null;
    };
    page?: {
      __typename?: 'Page';
      avatar?: string | null;
      name: string;
      id: string;
      createPostFee: string;
      createCommentFee: string;
      pageAccount: { __typename?: 'Account'; id: number; name: string; address: string; hash160?: string | null };
    } | null;
    token?: { __typename?: 'Token'; id: string; name: string; tokenId: string } | null;
    reposts?: Array<{
      __typename?: 'Repost';
      accountId?: number | null;
      account?: { __typename?: 'Account'; id: number; name: string; address: string } | null;
    }> | null;
    dana?: {
      __typename?: 'PostDana';
      danaBurnUp: number;
      danaBurnDown: number;
      danaBurnScore: number;
      danaReceivedUp: number;
      danaReceivedDown: number;
      danaReceivedScore: number;
      version: number;
    } | null;
    boostScore?: { __typename?: 'PostBoost'; boostScore: number; boostUp: number; boostDown: number } | null;
    translations?: Array<{
      __typename?: 'PostTranslation';
      id: string;
      translateContent?: string | null;
      translateLanguage?: string | null;
    }> | null;
    imageUploadable?: {
      __typename?: 'ImageUploadable';
      id: string;
      uploads: Array<{
        __typename?: 'Upload';
        id: string;
        sha: string;
        bucket?: string | null;
        width?: number | null;
        height?: number | null;
        cfImageId?: string | null;
        cfImageFilename?: string | null;
      }>;
    } | null;
    poll?: {
      __typename?: 'Poll';
      postId: string;
      question: string;
      startDate: any;
      endDate: any;
      canAddOption: boolean;
      singleSelect: boolean;
      defaultOptions?: Array<string> | null;
      totalVote?: number | null;
      options: Array<{
        __typename?: 'PollOption';
        id: string;
        option: string;
        pollId: string;
        danaScoreOption?: number | null;
        pollAnswerOnAccount?: Array<{
          __typename?: 'PollAnswerOnAccount';
          pollDanaScore: number;
          accountId: number;
        }> | null;
      }>;
    } | null;
    postOffer?: {
      __typename?: 'Offer';
      postId: string;
      publicKey: string;
      message: string;
      noteOffer?: string | null;
      price: string;
      coinPayment?: string | null;
      coinOthers?: string | null;
      marginPercentage: number;
      localCurrency?: string | null;
      orderLimitMin: number;
      orderLimitMax: number;
      hideFromHome?: boolean | null;
      status: Types.OfferStatus;
      locationId?: string | null;
      countryId?: number | null;
      paymentMethods: Array<{
        __typename?: 'OfferPaymentMethod';
        paymentMethod: { __typename?: 'PaymentMethod'; id: number; name: string };
      }>;
      location?: {
        __typename?: 'Location';
        id: string;
        iso2?: string | null;
        country?: string | null;
        adminNameAscii?: string | null;
        adminCode?: string | null;
        cityAscii?: string | null;
      } | null;
      country?: { __typename?: 'Country'; name?: string | null } | null;
    } | null;
  };
};

export type CreateVoteMutationVariables = Types.Exact<{
  input: Types.CreateVoteInput;
}>;

export type CreateVoteMutation = {
  __typename?: 'Mutation';
  createVote: { __typename?: 'PollAnswerOnAccount'; pollDanaScore: number };
};

export const CreatePollDocument = `
    mutation createPoll($input: CreatePollInput!) {
  createPoll(data: $input) {
    ...PostFields
  }
}
    ${PostFieldsFragmentDoc}`;
export const CreateVoteDocument = `
    mutation createVote($input: CreateVoteInput!) {
  createVote(data: $input) {
    pollDanaScore
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    createPoll: build.mutation<CreatePollMutation, CreatePollMutationVariables>({
      query: variables => ({ document: CreatePollDocument, variables })
    }),
    createVote: build.mutation<CreateVoteMutation, CreateVoteMutationVariables>({
      query: variables => ({ document: CreateVoteDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
